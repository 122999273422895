import {
	MDBBtn,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBox,
	MDBAnimation,
} from "mdbreact";
import * as React from "react";

import AnimatedHeaderBegin from "../components/Headers/AnimatedHeaderBegin/AnimatedHeaderBegin";
import SEO from "../components/SEO/Seo";
import { Link } from "gatsby";
import Layout from "../Layouts/MasterLayout/MasterLayout";

// Assets header
import BackgroundImage from "../assets/images/home/headerBackground.png";
import LetteringImage from "../assets/images/home/lettering.png";
//import ModalidadesImage from "../assets/images/home/modalidades.png";
import CarouselCampanha2022 from "../components/CarouselCampanha2022/CarouselCampanha2022";
import imgPc from "../assets/images/home/curvas_nivel.png";

import HeaderBalloonSmall from "../assets/images/home/lettering.png";
import HeaderBackgroundSmall from "../assets/images/home/headerBackgroundMobile.png";

import "../components/Css/index.css"

// Imagens de topo
let LetteringImageProp = (
	<img
		src={LetteringImage}
		alt="Vestibular Feevale"
		className="img-fluid d-flex ml-5 ml-md-0 align-self-center justify-content-center"
	/>
);

let HeaderBallonPropSmall = (
	<img
		src={HeaderBalloonSmall}
		alt="Conheça as vantagens para ingressar na Universidade Feevale. Bolsas e descontos."
		className="img-fluid"
	/>
);

// let ModalidadesImageProp = (
// 	<img
// 		src={ModalidadesImage}
// 		alt="Escolha sua modaldade: Presencial, Híbrido ou Digital-EAD"
// 		className="img-fluid"
// 	/>
// );

export default function Index() {
	return (
		<Layout color="#6f47a3">
			<SEO
				title="Graduação"
				description="Conheça a graduação da Universidade Feevale. Ingresse via vestibular, transferências e reingresso. Inscrições abertas."
			/>
			<AnimatedHeaderBegin
				contentA={LetteringImageProp}
				//contentB={HeaderBallonPropSmall}
				// contentB={ModalidadesImageProp}
				image={BackgroundImage}
				imageb={HeaderBackgroundSmall}
			/>
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol className="p-0 m-0">
						<Link to="/inscrevase">
							<MDBBtn
								size="lg"
								block
								color="secondary"
								className="text-primary m-0 shadow-none h4 font-weight-bold"
							>
								Verifique as formas de ingresso e realize a sua inscrição
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>

			<div className="text-center text-md-left">
				{/*<MDBBox bgColor="primary">
					<MDBContainer>
						<MDBRow className="text-white py-4 pt-5">
							<MDBCol>
								<h1 className="text-white font-weight-bold text-nowrap">
									Graduação
									<br />
									Presencial
								</h1>
								<p>
									Tudo que a experiência presencial tem de melhor! Na Feevale, o
									estudante é protagonista e aprende com práticas que o colocam
									em situações próximas do mercado. Além disso, dispõe de uma
									infraestrutura de excelência, com laboratórios e espaços de
									aprendizagem de alta qualidade.
								</p>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
	</MDBBox>*/}
				<MDBBox className="primary-color">
					<MDBContainer>
						<MDBRow className="py-4">
							<MDBCol style={{color:"#303030"}}>
								<h1 className="font-weight-bold text-nowrap">
									Graduação
									<br />
									Presencial
								</h1>
								<p>
									A combinação perfeita entre o presencial e o digital! São
									cursos dinâmicos e atuais, organizados por módulos. Toda a 
									experiência presencial da Feevale com o suporte e complemento
									dos conteúdos digitais.
								</p>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBBox>
				<MDBBox className="pb-3 primary-color">
					<MDBContainer>
						<MDBRow className="py-4 pb-5" style={{color:"#303030"}}>
							<MDBCol>
								<h1 className="font-weight-bold text-nowrap">
									Graduação
									<br />
									EAD
								</h1>
								<p>
									Garanta a qualidade Feevale onde você estiver! Cursos pensados
									para proporcionar a melhor experiência de
									aprendizagem, com os professores e experiências presenciais / síncronas.
									Faça o seu caminho em qualquer lugar do mundo.
								</p>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBBox>
			</div>
			<div className="secondary-color">
			<MDBContainer>
				<MDBRow
					className="text-center"
					style={{
						transform: "translateY(-50%)",
					}}
				>
					<MDBCol md="12">
						<Link to="/cursos">
							<MDBBtn className="text-white font-weight-bold" color="secondary">
								Conheça nossos cursos
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			</div>
			{/* ----------------------------------------------------------------------- */}
			<div className="secondary-color">
			<MDBContainer className="pb-4">
				<MDBRow className="pt-4">
					<MDBCol md={6} className="pb-2 my-auto">
						<h3 className="text-primary font-weight-bold">
							ESCOLHA SEU CAMINHO
						</h3>
						<p className="text-primary">
							Prepare-se para o mundo hiperconectado do século XXI. Trilhe sua
							própria jornada de a prendizagem com aulas e projetos voltados às
							soluções de problemas reais com atividades que podem ser feitas
							tanto no Feevale Techpark quanto no Hub One de Criatividade e
							Inovação, no Hospital Veterinário Feevale, CIES, empresas
							incubadas e muito mais.
						</p>
					</MDBCol>
					
					<MDBCol md={6} className="d-flex align-items-center justify-content-center">
						<iframe 
							height="252" 
							width="438" 
							src="https://www.youtube.com/embed/nbklzIjrx9U?si=OhncU4CSsyjnoLef" 
							title="Venha viver experiências transformadoras aqui na Universidade Feevale." 
							frameborder="0" 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
							allowfullscreen
							style={{border: "2px solid #ebebeb", padding:"5px"}}>
								
						</iframe>
					</MDBCol>
					
				</MDBRow>
			</MDBContainer>
			</div>
			{/* ----------------------------------------------------------------------- */}
			<div className="secondary-color">
			<MDBContainer className="pt-4">
				<MDBRow className="pb-5 text-center">
					<MDBCol>
						<Link to="/inscrevase">
							<MDBBtn className="px-5" color="primary" style={{color:"#303030"}}>
								<span className="h6 font-weight-bold">
									COMECE A ESTUDAR NA UNIVERSIDADE FEEVALE
								</span>
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			</div>
			{/* ----------------------------------------------------------------------- */}
			
			<MDBBox bgColor="primary">
			<div style={{ backgroundImage: `url(${imgPc})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%' }}>
				<MDBContainer className="text-white pt-5" >
					<MDBRow className="pb-5">
						<MDBCol md={12} className="pr-md-5 mr-md-5">
							<MDBRow className="pb-4">
								<MDBCol className="text-center">
									<h3 className="font-weight-bold text-primary bg-secondary pb-2 pt-2">BOLSAS E FINANCIAMENTOS</h3>
								</MDBCol>
							</MDBRow>
							<MDBRow className="imagensindex">
							
								<MDBCol md="3" className="pb-2 ">
									<a
										href="https://www.feevale.br/institucional/atendimento-financeiro-e-financiamento-estudantil/opcoes-de-financiamento"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="https://www.feevale.br/s/conteudo/cdcc3fb4-63ef-400f-8987-252ddc8a639c/Banner-Banrisul.jpg"
											alt="Crédito universitário Banrisul. A gente descomplica pra você"
											className="img-fluid col-12 p-0 rounded-lg"
										/>
									</a>
								</MDBCol>
								{/* <MDBCol col={6} xs={6} md={6} className="pb-2">
									<Link to={"/descontos"}>
										<img
											src="https://via.placeholder.com/487x228/FFFFFF/000000?text=BANNER%20DESCONTOS%20(487x228)"
											alt="Bolsas de até 50%* e 30%*! Clique e acesse"
											className="img-fluid col-12 p-0 rounded-lg"
										/>
									</Link>
								</MDBCol> */}
								<MDBCol md="3" className="pb-2 " >
									<a
										href="https://www.pravaler.com.br/campanhas/lp-campeao/?ies_code=131&nome=FEEVALE&faculdade"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="https://www.feevale.br/s/conteudo/2fdcb54e-5c7d-499a-afc9-bdb834ebffcd/MicrosoftTeams-image_(27).png"
											alt="Financiamento estudantil SEM JUROS é com Pravaler."
											className="img-fluid col-12 p-0 rounded-lg"
										/>
									</a>
								</MDBCol>
								<MDBCol md="3" className="pb-2 ">
									<a
										href="https://sicredipioneira.com.br/fale-conosco"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="https://www.feevale.br/s/conteudo/fd7723a3-9906-4d39-86e0-285e8ccb5886/Banner-Sicredi.jpg"
											alt="Crédito universitário Sicredi"
											className="img-fluid col-12 p-0 rounded-lg"
										/>
									</a>
								</MDBCol>
								<MDBCol md="3" className="pb-2 ">
									<a
										href="https://www.feevale.br/institucional/atendimento-financeiro-e-financiamento-estudantil/opcoes-de-financiamento/credies-feevale"
										target="_blank"
										rel="noreferrer"
									>
										<img
											src="https://www.feevale.br/s/conteudo/4566498c-a049-4b68-91ff-7217b59473e7/Banner-Credies.png"
											alt="CredIES Feevale sua graduação é agora! acesse credies.org"
											className="img-fluid col-12 p-0 rounded-lg"
										/>
									</a>
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
				</div>
			</MDBBox>

			{/* ----------------------------------------------------------------------- */}
			<div className="secondary-color">
			<MDBContainer className="pt-5">
				
				<MDBRow>
					<MDBCol>
						<h3 className="text-primary font-weight-bold">ENTENDA O FINANCIAMENTO ESTUDANTIL</h3>
					</MDBCol>
				</MDBRow>
				 
				<MDBRow className="pb-3">
					<MDBCol>{/*
						<p className="text-black">
							Escolher uma graduação não é uma tarefa fácil e a gente sabe
							disso. Pra ajudar, criamos uma série de conteúdos bem bacanas pra
							entender e escolher o curso que mais combina com você.
						</p>*/}
					</MDBCol>
				</MDBRow>
				
				
				<MDBRow className="text-center imagensindex">
					{/* 
					<MDBCol xs="12" sm="12" md="3" className="pb-2">
						<a
							href="https://conteudo.feevale.br/qual-a-diferenca-entre-bacharelado-licenciatura-e-tecnologo"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="https://www.feevale.br/s/conteudo/2dd612d1-a49e-45dd-8812-4baca2f816cd/04_Institucional24_IdentidadeVisual_BacharelLicenTenol_487x228px.png"
								alt=""
								className="img-fluid col-12 p-0 rounded-lg"
							/>
						</a>
					</MDBCol>
					*/}
					<MDBCol xs="12" sm="12" md="3" className="pb-2">
						<a
							href="https://conteudo.feevale.br/financiamento-estudantil"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="https://www.feevale.br/s/conteudo/f6db6951-87a4-4e5c-961b-bd06b68526d8/04_Institucional24_IdentidadeVisual_Finaciamento_487x228px.png"
								alt=""
								className="img-fluid col-12 p-0 rounded-lg"
							/>
						</a>
					</MDBCol>
					{/* 
					<MDBCol xs="12" sm="12" md="3" className="pb-2">
						<a
							href="https://way.feevale.br/quiz/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="https://www.feevale.br/s/conteudo/dfb51e41-6fff-45ab-8c8c-12c8605dfe0e/04_Institucional24_IdentidadeVisual_OqueCursar_487x228px.png"
								alt=""
								className="img-fluid col-12 p-0 rounded-lg"
							/>
						</a>
					</MDBCol>
					*/}
				</MDBRow>
			</MDBContainer>
			</div>
		</Layout>
	);
}
